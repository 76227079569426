import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { TableContainer } from "shared/CommonStyles";
import { TableWrapper } from 'shared/tableWrapper';

interface IProps {
  match?: any;
}


export const ManagementForms: React.FC<IProps> = ({ match }: IProps) => {
  return (
    <>
      <PageHeader title='Form Capture' />
      <TableContainer>
        <TableWrapper columns={[
          'Created At (ID)',
          'Content',
        ]}>

        </TableWrapper>
      </TableContainer>
    </>
  );
}
