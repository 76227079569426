import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { TableContainer } from "shared/CommonStyles";
import { gql } from '@apollo/client';
import { ModifyUserAdminInput, useOrganizationAdminUserModifyMutation, useOrganizationUsersQuery } from 'types/gqlReactTypings.generated.d';
import { AuthContext } from 'shared/Authentication';
import { formatBoolean, formatDate, getOrganizationId } from 'shared/Utilities';
import { compact } from 'lodash';
import { Button } from 'react-bootstrap';
import { TableWrapper } from 'shared/tableWrapper';

interface IProps {
  match?: any;
}

gql`
  query OrganizationUsers($organizationId: String!) {
    organizationUsers(organizationId: $organizationId) {
      id, fullName, email, phoneNumber, verified, admin, createdAt, active
    }
  }
`;


export const ManagementUsers: React.FC<IProps> = ({ match }: IProps) => {
  const { currentUser } = React.useContext(AuthContext);
  const { data, refetch: refetchUsers } = useOrganizationUsersQuery({ variables: { organizationId: getOrganizationId(currentUser) ?? "DNE" } });
  const [organizationAdminUserModifyMutation] = useOrganizationAdminUserModifyMutation();

  const modifyUser = (userId: string, modifyUserAdminInput: ModifyUserAdminInput) => () => {
    organizationAdminUserModifyMutation({ variables: { modifyUserAdminInput, userId } }).then(() => {
      refetchUsers?.();
    });
  }

  const organizationUser = compact(data?.organizationUsers);
  return (
    <>
      <PageHeader title='Manage Users' />
      <TableContainer>
        <TableWrapper columns={[
          'Name (ID)',
          'Email',
          'Phone',
          'Is Active?',
          'Is Verified?',
          'Is Admin?',
          'Created At',
          'Actions'
        ]}>
          {organizationUser.map(user => (
            <tr key={user.id}>
              <td>{user.fullName} ({user.id})</td>
              <td>{user.email}</td>
              <td>{user.phoneNumber}</td>
              <td>{formatBoolean(user.active)}</td>
              <td>{formatBoolean(user.verified)}</td>
              <td>{formatBoolean(user.admin)}</td>
              <td>{formatDate(user.createdAt)}</td>
              <td>
                {!!user.active ?
                  <Button variant='warning' size='sm' onClick={modifyUser(user.id, { active: false })}>Disable User</Button>
                  :
                  <Button variant='success' size='sm' onClick={modifyUser(user.id, { active: true })}>Enable User</Button>
                }
              </td>
            </tr>
          ))}

        </TableWrapper>
      </TableContainer>
    </>
  );
}
