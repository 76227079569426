/* THIS IS A GENERATED FILE - DO NOT MODIFY */

/* eslint-disable */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AdminMetrics = {
  __typename?: 'AdminMetrics';
  userCount: Scalars['Int']['output'];
};

export type AdminModifyUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  newPasswordConfirmation: Scalars['String']['input'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type ModifyOrganizationUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyUserAdminInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrganization: Organization;
  addSubscription: Array<ProductSubscription>;
  adminModifyUser: User;
  changePassword: Scalars['Boolean']['output'];
  forgotPasswordRequest: Scalars['Boolean']['output'];
  login: LoginResponse;
  modifyUser: User;
  organizationAdminUserModify: OrganizationUser;
  organizationFormCreate: Scalars['Boolean']['output'];
  organizationUserChangePassword: Scalars['Boolean']['output'];
  organizationUserForgotPasswordRequest: Scalars['Boolean']['output'];
  organizationUserLogin: OrganizationUser;
  organizationUserModify: OrganizationUser;
  organizationUserRegister: OrganizationUser;
  organizationUserResetPassword: Scalars['Boolean']['output'];
  organizationUserVerify: Scalars['Boolean']['output'];
  ping: Scalars['String']['output'];
  register: LoginResponse;
  resetPassword: Scalars['Boolean']['output'];
  subscribe: Array<ProductSubscription>;
};


export type MutationAddOrganizationArgs = {
  organizationInput: OrganizationInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  products: Array<ProductType>;
};


export type MutationAdminModifyUserArgs = {
  modifyUserInput: AdminModifyUserInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationForgotPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationModifyUserArgs = {
  modifyUserInput: ModifyUserInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationAdminUserModifyArgs = {
  modifyUserAdminInput: ModifyUserAdminInput;
  userId: Scalars['String']['input'];
};


export type MutationOrganizationFormCreateArgs = {
  organizationFormInput: OrganizationFormInput;
};


export type MutationOrganizationUserChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationUserForgotPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationUserLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationUserModifyArgs = {
  modifyUserInput: ModifyUserInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationUserRegisterArgs = {
  organizationUserInput: OrganizationUserInput;
};


export type MutationOrganizationUserResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type MutationOrganizationUserVerifyArgs = {
  code: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  userInput: UserInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type MutationSubscribeArgs = {
  organizationId: Scalars['String']['input'];
  subscriptionInput: SubscriptionInput;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgToken: Scalars['String']['output'];
  ownerUserId: Scalars['String']['output'];
  productSubscriptions?: Maybe<Array<Maybe<ProductSubscription>>>;
  validateEmail: Scalars['Boolean']['output'];
};

export type OrganizationForm = {
  __typename?: 'OrganizationForm';
  active: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  organizationId: Scalars['String']['output'];
};

export type OrganizationFormInput = {
  content: Scalars['String']['input'];
};

export type OrganizationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  active: Scalars['Boolean']['output'];
  admin: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type OrganizationUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: ProductType;
  name: Scalars['String']['output'];
  priceCents: Scalars['Int']['output'];
};

export type ProductSubscription = {
  __typename?: 'ProductSubscription';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['String']['output'];
  product: Product;
  productId: Scalars['String']['output'];
  stripeSubscriptionItemId: Scalars['String']['output'];
};

export enum ProductType {
  FormCapture = 'FORM_CAPTURE',
  StaticWebsiteHosting = 'STATIC_WEBSITE_HOSTING',
  UserAuthAndManagement = 'USER_AUTH_AND_MANAGEMENT'
}

export type Query = {
  __typename?: 'Query';
  adminMetrics: AdminMetrics;
  adminUsers: Array<User>;
  me?: Maybe<LoginResponse>;
  organizationForms: Array<OrganizationForm>;
  organizationUserMe?: Maybe<OrganizationUser>;
  organizationUsers: Array<OrganizationUser>;
  productsAvailable: Array<Product>;
  subscriptions: Array<ProductSubscription>;
  version: Scalars['String']['output'];
};


export type QueryAdminUsersArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryOrganizationFormsArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationUsersArgs = {
  organizationId: Scalars['String']['input'];
};


export type QuerySubscriptionsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionInput = {
  paymentId: Scalars['String']['input'];
  products: Array<ProductType>;
};

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean']['output'];
  androidPushToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  iosPushToken?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<Organization>>>;
  phoneNumber: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminMetricsQuery = { __typename?: 'Query', adminMetrics: { __typename?: 'AdminMetrics', userCount: number } };

export type AdminUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminUsersQuery = { __typename?: 'Query', adminUsers: Array<{ __typename?: 'User', id: string, email: string, phoneNumber: string, fullName: string, admin: boolean, verified: boolean, createdAt?: any | undefined }> };

export type OrganizationUsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationUsersQuery = { __typename?: 'Query', organizationUsers: Array<{ __typename?: 'OrganizationUser', id: string, fullName?: string | undefined, email?: string | undefined, phoneNumber?: string | undefined, verified: boolean, admin: boolean, createdAt?: any | undefined, active: boolean }> };

export type AddOrganizationMutationVariables = Exact<{
  organizationInput: OrganizationInput;
}>;


export type AddOrganizationMutation = { __typename?: 'Mutation', addOrganization: { __typename?: 'Organization', id: string } };

export type SubscribeMutationVariables = Exact<{
  subscriptionInput: SubscriptionInput;
  organizationId: Scalars['String']['input'];
}>;


export type SubscribeMutation = { __typename?: 'Mutation', subscribe: Array<{ __typename?: 'ProductSubscription', id: number }> };

export type AddSubscriptionMutationVariables = Exact<{
  products: Array<ProductType> | ProductType;
  organizationId: Scalars['String']['input'];
}>;


export type AddSubscriptionMutation = { __typename?: 'Mutation', addSubscription: Array<{ __typename?: 'ProductSubscription', id: number }> };

export type GenericProductSubscriptionFragment = { __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } };

export type GenericUserFieldsFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined };

export type CurrentUserFieldsFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined } } | undefined };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined } } };

export type RegisterMutationVariables = Exact<{
  userInput: UserInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined } } };

export type AdminModifyUserMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  modifyUserInput: AdminModifyUserInput;
}>;


export type AdminModifyUserMutation = { __typename?: 'Mutation', adminModifyUser: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email: string, phoneNumber: string, admin: boolean, createdAt?: any | undefined, organizations?: Array<{ __typename?: 'Organization', id: string, name: string, email: string, orgToken: string, productSubscriptions?: Array<{ __typename?: 'ProductSubscription', id: number, productId: string, stripeSubscriptionItemId: string, createdAt: any, product: { __typename?: 'Product', id: ProductType, name: string, priceCents: number } } | undefined> | undefined } | undefined> | undefined } };

export type OrganizationAdminUserModifyMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  modifyUserAdminInput: ModifyUserAdminInput;
}>;


export type OrganizationAdminUserModifyMutation = { __typename?: 'Mutation', organizationAdminUserModify: { __typename?: 'OrganizationUser', id: string } };

export type ProductsAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsAvailableQuery = { __typename?: 'Query', productsAvailable: Array<{ __typename?: 'Product', id: ProductType, name: string, priceCents: number, description: string, active: boolean }> };

export const GenericProductSubscriptionFragmentDoc = gql`
    fragment GenericProductSubscription on ProductSubscription {
  id
  productId
  stripeSubscriptionItemId
  createdAt
  product {
    id
    name
    priceCents
  }
}
    `;
export const GenericUserFieldsFragmentDoc = gql`
    fragment GenericUserFields on User {
  id
  firstName
  lastName
  fullName
  email
  phoneNumber
  admin
  createdAt
  organizations {
    id
    name
    email
    orgToken
    productSubscriptions {
      ...GenericProductSubscription
    }
  }
}
    ${GenericProductSubscriptionFragmentDoc}`;
export const CurrentUserFieldsFragmentDoc = gql`
    fragment CurrentUserFields on User {
  ...GenericUserFields
}
    ${GenericUserFieldsFragmentDoc}`;
export const AdminMetricsDocument = gql`
    query AdminMetrics {
  adminMetrics {
    userCount
  }
}
    `;

/**
 * __useAdminMetricsQuery__
 *
 * To run a query within a React component, call `useAdminMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminMetricsQuery(baseOptions?: Apollo.QueryHookOptions<AdminMetricsQuery, AdminMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMetricsQuery, AdminMetricsQueryVariables>(AdminMetricsDocument, options);
      }
export function useAdminMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMetricsQuery, AdminMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMetricsQuery, AdminMetricsQueryVariables>(AdminMetricsDocument, options);
        }
export type AdminMetricsQueryHookResult = ReturnType<typeof useAdminMetricsQuery>;
export type AdminMetricsLazyQueryHookResult = ReturnType<typeof useAdminMetricsLazyQuery>;
export type AdminMetricsQueryResult = Apollo.QueryResult<AdminMetricsQuery, AdminMetricsQueryVariables>;
export const AdminUsersDocument = gql`
    query AdminUsers {
  adminUsers {
    id
    email
    phoneNumber
    fullName
    admin
    verified
    createdAt
  }
}
    `;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
      }
export function useAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
        }
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = Apollo.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const OrganizationUsersDocument = gql`
    query OrganizationUsers($organizationId: String!) {
  organizationUsers(organizationId: $organizationId) {
    id
    fullName
    email
    phoneNumber
    verified
    admin
    createdAt
    active
  }
}
    `;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
      }
export function useOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
        }
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = Apollo.QueryResult<OrganizationUsersQuery, OrganizationUsersQueryVariables>;
export const AddOrganizationDocument = gql`
    mutation AddOrganization($organizationInput: OrganizationInput!) {
  addOrganization(organizationInput: $organizationInput) {
    id
  }
}
    `;
export type AddOrganizationMutationFn = Apollo.MutationFunction<AddOrganizationMutation, AddOrganizationMutationVariables>;

/**
 * __useAddOrganizationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMutation, { data, loading, error }] = useAddOrganizationMutation({
 *   variables: {
 *      organizationInput: // value for 'organizationInput'
 *   },
 * });
 */
export function useAddOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationMutation, AddOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationMutation, AddOrganizationMutationVariables>(AddOrganizationDocument, options);
      }
export type AddOrganizationMutationHookResult = ReturnType<typeof useAddOrganizationMutation>;
export type AddOrganizationMutationResult = Apollo.MutationResult<AddOrganizationMutation>;
export type AddOrganizationMutationOptions = Apollo.BaseMutationOptions<AddOrganizationMutation, AddOrganizationMutationVariables>;
export const SubscribeDocument = gql`
    mutation Subscribe($subscriptionInput: SubscriptionInput!, $organizationId: String!) {
  subscribe(
    subscriptionInput: $subscriptionInput
    organizationId: $organizationId
  ) {
    id
  }
}
    `;
export type SubscribeMutationFn = Apollo.MutationFunction<SubscribeMutation, SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      subscriptionInput: // value for 'subscriptionInput'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, options);
      }
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const AddSubscriptionDocument = gql`
    mutation AddSubscription($products: [ProductType!]!, $organizationId: String!) {
  addSubscription(products: $products, organizationId: $organizationId) {
    id
  }
}
    `;
export type AddSubscriptionMutationFn = Apollo.MutationFunction<AddSubscriptionMutation, AddSubscriptionMutationVariables>;

/**
 * __useAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionMutation, { data, loading, error }] = useAddSubscriptionMutation({
 *   variables: {
 *      products: // value for 'products'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAddSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddSubscriptionMutation, AddSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubscriptionMutation, AddSubscriptionMutationVariables>(AddSubscriptionDocument, options);
      }
export type AddSubscriptionMutationHookResult = ReturnType<typeof useAddSubscriptionMutation>;
export type AddSubscriptionMutationResult = Apollo.MutationResult<AddSubscriptionMutation>;
export type AddSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddSubscriptionMutation, AddSubscriptionMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($userInput: UserInput!) {
  register(userInput: $userInput) {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const AdminModifyUserDocument = gql`
    mutation AdminModifyUser($userId: String, $modifyUserInput: AdminModifyUserInput!) {
  adminModifyUser(modifyUserInput: $modifyUserInput, userId: $userId) {
    ...GenericUserFields
  }
}
    ${GenericUserFieldsFragmentDoc}`;
export type AdminModifyUserMutationFn = Apollo.MutationFunction<AdminModifyUserMutation, AdminModifyUserMutationVariables>;

/**
 * __useAdminModifyUserMutation__
 *
 * To run a mutation, you first call `useAdminModifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminModifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminModifyUserMutation, { data, loading, error }] = useAdminModifyUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      modifyUserInput: // value for 'modifyUserInput'
 *   },
 * });
 */
export function useAdminModifyUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminModifyUserMutation, AdminModifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminModifyUserMutation, AdminModifyUserMutationVariables>(AdminModifyUserDocument, options);
      }
export type AdminModifyUserMutationHookResult = ReturnType<typeof useAdminModifyUserMutation>;
export type AdminModifyUserMutationResult = Apollo.MutationResult<AdminModifyUserMutation>;
export type AdminModifyUserMutationOptions = Apollo.BaseMutationOptions<AdminModifyUserMutation, AdminModifyUserMutationVariables>;
export const OrganizationAdminUserModifyDocument = gql`
    mutation OrganizationAdminUserModify($userId: String!, $modifyUserAdminInput: ModifyUserAdminInput!) {
  organizationAdminUserModify(
    modifyUserAdminInput: $modifyUserAdminInput
    userId: $userId
  ) {
    id
  }
}
    `;
export type OrganizationAdminUserModifyMutationFn = Apollo.MutationFunction<OrganizationAdminUserModifyMutation, OrganizationAdminUserModifyMutationVariables>;

/**
 * __useOrganizationAdminUserModifyMutation__
 *
 * To run a mutation, you first call `useOrganizationAdminUserModifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAdminUserModifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationAdminUserModifyMutation, { data, loading, error }] = useOrganizationAdminUserModifyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      modifyUserAdminInput: // value for 'modifyUserAdminInput'
 *   },
 * });
 */
export function useOrganizationAdminUserModifyMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationAdminUserModifyMutation, OrganizationAdminUserModifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationAdminUserModifyMutation, OrganizationAdminUserModifyMutationVariables>(OrganizationAdminUserModifyDocument, options);
      }
export type OrganizationAdminUserModifyMutationHookResult = ReturnType<typeof useOrganizationAdminUserModifyMutation>;
export type OrganizationAdminUserModifyMutationResult = Apollo.MutationResult<OrganizationAdminUserModifyMutation>;
export type OrganizationAdminUserModifyMutationOptions = Apollo.BaseMutationOptions<OrganizationAdminUserModifyMutation, OrganizationAdminUserModifyMutationVariables>;
export const ProductsAvailableDocument = gql`
    query ProductsAvailable {
  productsAvailable {
    id
    name
    priceCents
    description
    active
  }
}
    `;

/**
 * __useProductsAvailableQuery__
 *
 * To run a query within a React component, call `useProductsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsAvailableQuery(baseOptions?: Apollo.QueryHookOptions<ProductsAvailableQuery, ProductsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsAvailableQuery, ProductsAvailableQueryVariables>(ProductsAvailableDocument, options);
      }
export function useProductsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsAvailableQuery, ProductsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsAvailableQuery, ProductsAvailableQueryVariables>(ProductsAvailableDocument, options);
        }
export type ProductsAvailableQueryHookResult = ReturnType<typeof useProductsAvailableQuery>;
export type ProductsAvailableLazyQueryHookResult = ReturnType<typeof useProductsAvailableLazyQuery>;
export type ProductsAvailableQueryResult = Apollo.QueryResult<ProductsAvailableQuery, ProductsAvailableQueryVariables>;