import React from 'react';
import { OnboardingLabel, OnboardingWrapper } from './components/onboardingWrapper';
import { Colors } from 'shared/Constants';
import { formatCurrency, testingOnlyData } from 'shared/Utilities';
import { FormGroup } from 'react-bootstrap';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { PaymentForm } from 'shared/paymentForm';
import { ProductType, useProductsAvailableQuery, useAddOrganizationMutation, useSubscribeMutation } from 'types/gqlReactTypings.generated.d';
import { compact, first, sum } from 'lodash';
import { gql } from '@apollo/client';
import { AuthContext } from 'shared/Authentication';
import { ProductSelection } from './components/productSelection';

gql`
  mutation AddOrganization($organizationInput: OrganizationInput!) {
    addOrganization(organizationInput: $organizationInput) {
      id
    }
  }

  mutation Subscribe($subscriptionInput: SubscriptionInput!, $organizationId: String!) {
    subscribe(subscriptionInput: $subscriptionInput, organizationId: $organizationId) {
      id
    }
  }

  mutation AddSubscription($products: [ProductType!]!, $organizationId: String!) {
    addSubscription(products: $products, organizationId: $organizationId) {
      id
    }
  }
`;

export const CreateOrganization: React.FC = () => {
  const { currentUser } = React.useContext(AuthContext);
  const [email, setEmail] = React.useState<string>(testingOnlyData(`philip+${Math.round(Math.random() * 1000000)}@appify.co`, ''));
  const [name, setName] = React.useState<string>(testingOnlyData('Test store', ''));
  const { data: productsData } = useProductsAvailableQuery();
  const [selectedProducts, setSelectedProducts] = React.useState<Set<ProductType>>(new Set());
  const availableProducts = compact(productsData?.productsAvailable);
  const [addOrganizationMutation] = useAddOrganizationMutation({ variables: { organizationInput: { email, name } } });
  const [subscribeMutation] = useSubscribeMutation();

  console.log("Showing create organization");

  const onPayment = async (paymentMethod: PaymentMethod) => {
    const { data } = await addOrganizationMutation();
    if (data?.addOrganization == null) {
      return window.alert("Failed to create organization");
    }

    subscribeMutation({
      variables: {
        organizationId: data.addOrganization.id,
        subscriptionInput: {
          paymentId: paymentMethod.id,
          products: Array.from(selectedProducts),
        },
      }
    }).then(() =>
      window.location.reload()
    );
  }

  const currentOrganization = first(currentUser?.organizations);

  return (
    <OnboardingWrapper>
      {/* <img src={imgLogo} style={{ maxWidth: '75%', marginBottom: 10 }} /> */}
      <h1 className="h3 mb-4" style={{ color: Colors.BLUE }}>Your Organization</h1>

      <FormGroup>
        <OnboardingLabel>Organization Name</OnboardingLabel>
        <input type="text"
          onChange={e => setName(e.target.value)}
          disabled={currentOrganization != null}
          value={currentOrganization?.name ?? name}
          className="form-control"
          placeholder="Organization name"
        />
      </FormGroup>

      <FormGroup>
        <OnboardingLabel>Organization Email</OnboardingLabel>
        <input type="email"
          onChange={e => setEmail(e.target.value)}
          value={currentOrganization?.email ?? email}
          className="form-control"
          placeholder="Organization email"
          disabled={currentOrganization != null}
        />
      </FormGroup>

      <OnboardingLabel>Product Selection ({selectedProducts.size})</OnboardingLabel>
      <ProductSelection
        availableProducts={availableProducts}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />

      <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'unset')}>
        <OnboardingLabel>Payment Details</OnboardingLabel>

        <PaymentForm
          onPayment={onPayment}
          disabled={selectedProducts.size === 0}
          createTitle={`Create Organization - ${formatCurrency(sum(availableProducts.filter(product => selectedProducts.has(product.id)).map(item => item.priceCents)))}/mo + tax`}
        />
      </Elements>
    </OnboardingWrapper >
  )
}