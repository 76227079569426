import React, { useContext, } from 'react';
import { useRegisterMutation, UserInput } from 'types/gqlReactTypings.generated.d';
import { AuthContext } from 'shared/Authentication';
import { getOnboardingAction, OnboardingLabel, OnboardingWrapper } from './components/onboardingWrapper';
import { Colors } from 'shared/Constants';
import { BlueButton } from 'shared/CommonStyles';
import { GeneralRoutes } from 'shared/Routes';
import { hookStateChangeInjector, testingOnlyData } from 'shared/Utilities';
import { Col, FormGroup, Row } from 'react-bootstrap';

export const Register: React.FC = () => {
  const [userInput, setNewUser] = React.useState<UserInput>({
    firstName: testingOnlyData('Test', ''),
    lastName: testingOnlyData('Test', ''),
    email: testingOnlyData(`philip+${Math.round(Math.random() * 1000000)}@appify.co`, ''),
    phoneNumber: testingOnlyData('650564' + Math.max(Math.round(Math.random() * 9999), 1000), ''),
    password: testingOnlyData('testtest', ''),
  });
  const { signIn } = useContext(AuthContext);
  const [registerMutation] = useRegisterMutation({ variables: { userInput } });
  const onChange = hookStateChangeInjector<UserInput>(userInput, setNewUser);

  console.log("Showing register");

  const onRegister = () => {
    registerMutation().then(({ data }) => {
      if (!data) {
        throw Error('No data for login');
      }

      const { token, user } = data?.register ?? {};

      if (!!token) {
        signIn(token, user);
      }
    }).catch(err => {
      console.error(err);
    });
  }

  return (
    <OnboardingWrapper>
      {/* <img src={imgLogo} style={{ maxWidth: '75%', marginBottom: 10 }} /> */}
      <h1 className="h3 mb-4" style={{ color: Colors.BLUE }}>Build <b>Better</b> with Webr</h1>

      <p style={{ marginBottom: 25 }}>Webr's Infrastructure as a Service (IaaS) empowers you to spend more time building product, and less time managing complex infrastructure.</p>


      <Row>
        <Col md={6}>
          <FormGroup>
            <OnboardingLabel>First Name</OnboardingLabel>
            <input type="text"
              onChange={e => onChange('firstName')(e.target.value)}
              value={userInput.firstName}
              className="form-control"
              placeholder="First name"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <OnboardingLabel>Last Name</OnboardingLabel>
            <input type="text"
              onChange={e => onChange('lastName')(e.target.value)}
              value={userInput.lastName}
              className="form-control"
              placeholder="Last name"
            />
          </FormGroup>
        </Col>
      </Row>


      <Row>
        <Col md={6}>
          <FormGroup>
            <OnboardingLabel>Email</OnboardingLabel>
            <input type="email"
              onChange={e => onChange('email')(e.target.value)}
              value={userInput.email}
              className="form-control"
              placeholder="Username"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <OnboardingLabel>Phone Number</OnboardingLabel>
            <input type="phone"
              onChange={e => onChange('phoneNumber')(e.target.value)}
              value={userInput.phoneNumber}
              className="form-control"
              placeholder="Phone Number"
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <OnboardingLabel>Password</OnboardingLabel>
        <input type="password"
          onChange={e => onChange('password')(e.target.value)}
          value={userInput.password}
          className="form-control"
          placeholder="Password"
        />
      </FormGroup>

      <BlueButton onClick={onRegister} style={{ width: '100%', marginTop: 10 }}>
        <span>Get started &rarr;</span>
      </BlueButton>

      {getOnboardingAction('Already have an account?', 'Access your existing Webr infrastructure', GeneralRoutes.LOGIN, 'Login')}


      {/* <div className="text-center">
                        <a className="small" href="register.html">Create an Account!</a>
                      </div> */}
    </OnboardingWrapper>
  )
}