import React from 'react';
import { Colors } from 'shared/Constants';
import { Col, Row } from 'react-bootstrap';
import { ProductsAvailableQuery, ProductType } from 'types/gqlReactTypings.generated.d';
import { formatCurrency } from 'shared/Utilities';

interface IProps {
  availableProducts: ProductsAvailableQuery['productsAvailable'];
  selectedProducts: Set<ProductType>;
  setSelectedProducts: React.Dispatch<React.SetStateAction<Set<ProductType>>>;
}

export const ProductSelection: React.FC<IProps> = ({ selectedProducts, setSelectedProducts, availableProducts }: IProps) => {

  return (
    <div style={{ marginBottom: 20 }}>
      <Row>
        {availableProducts.map(product => {
          const selected = selectedProducts.has(product.id);
          return (
            <Col md={6} key={product.id} style={{ marginBottom: 20 }}>
              <div
                onClick={() => {
                  const newSet = new Set(selectedProducts);
                  if (!!selected) {
                    newSet.delete(product.id);
                  } else {
                    newSet.add(product.id);
                  }
                  setSelectedProducts(newSet);
                }}
                style={{
                  cursor: 'pointer',
                  borderRadius: 5,
                  width: '100%', minHeight: 100,
                  // backgroundColor: selected ? Colors.PINK : undefined,
                  borderColor: selected ? Colors.PINK : '#ccc',
                  borderWidth: 3,
                  borderStyle: 'solid',
                  color: selected ? Colors.PINK : '#999',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: 10,
                }}
              >
                <span style={{ fontSize: 16 }}>{product.name}</span>
                <small><b>{formatCurrency(product.priceCents)}/mo</b></small>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  )
}