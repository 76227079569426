import React from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { BlueButton } from './CommonStyles';
import { PaymentMethod } from '@stripe/stripe-js';


interface IProps {
  disabled?: boolean;
  createTitle?: string;
  onPayment: (paymentMethod: PaymentMethod) => void;
}

export const PaymentForm: React.FC<IProps> = ({ disabled, createTitle, onPayment }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    if (stripe == null) {
      return window.alert("Failed to submit payment.  Stripe undefined");
    } else if (elements == null) {
      return window.alert("Failed to submit payment.  Elements undefined");
    }

    const card = elements.getElement(CardElement);
    if (card == null) {
      return window.alert("Failed to submit payment.  Card not entered.");
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error != null) {
      console.error(error);
      return window.alert(`Failed to submit payment.  ${error.message}`);
    }

    if (paymentMethod == null) {
      return console.error("no payment method");
    }

    console.log(paymentMethod);
    onPayment(paymentMethod);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ border: '1px solid #ced4da', padding: '10px 15px', borderRadius: 5, marginBottom: 20 }}>
        <CardElement options={{ style: { base: {} } }} />
      </div>
      <BlueButton
        disabled={!stripe || !!disabled}
        type='submit' onClick={() => undefined} style={{ width: '100%', marginTop: 10 }}>
        <span>{createTitle} &rarr;</span>
      </BlueButton>
    </form>
  )
}