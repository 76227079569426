import { MetricCard } from 'components/MetricCard';
import { compact, first, sum } from 'lodash';
import { OnboardingLabel } from 'pages/onboarding/components/onboardingWrapper';
import { ProductSelection } from 'pages/onboarding/components/productSelection';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AuthContext } from 'shared/Authentication';
import { ManagementRoutes } from 'shared/Routes';
import { GenericProductSubscriptionFragment, ProductType, useProductsAvailableQuery, useAddSubscriptionMutation } from 'types/gqlReactTypings.generated.d';
import { PageHeader } from '../../components/PageHeader';
import { TableWrapper } from 'shared/tableWrapper';
import { formatCurrency, formatDate } from 'shared/Utilities';

export const ManagementDashboard: React.FC = () => {
  const { data: productsData } = useProductsAvailableQuery();
  const [subscribeMutation] = useAddSubscriptionMutation();
  const { currentUser } = React.useContext(AuthContext);
  const [selectedProducts, setSelectedProducts] = React.useState<Set<ProductType>>(new Set());

  const org = first(currentUser?.organizations);
  const subscriptions = compact(org?.productSubscriptions);
  const availableProducts = compact(productsData?.productsAvailable);


  const hasProductType = (type: ProductType) => {
    return subscriptions.some(item => item.productId === type)
  }

  const onAddSubscription = () => {
    if (org != null && window.confirm('Are you sure? Your card on file will be charged.')) {
      subscribeMutation({
        variables: {
          organizationId: org.id,
          products: Array.from(selectedProducts),
        }
      }).then(() =>
        window.location.reload()
      );
    }
  }

  const onCancel = (subscription: GenericProductSubscriptionFragment) => () => {
    if (window.confirm('Are you sure? This subscription will end immediately')) {
      console.log(subscription);
      window.alert("This functionality is under temporary maintenance.  Please email team@webr.co");
    }
  }

  return (
    <div >
      <PageHeader title='Organization Dashboard' />
      <Row style={{ marginBottom: 20 }}>
        <Col md={2}>
          <OnboardingLabel>Email</OnboardingLabel>
          <p>{org?.email}</p>
        </Col>
        <Col md={2}>
          <OnboardingLabel>Name</OnboardingLabel>
          <p>{org?.name}</p>
        </Col>
        <Col md={8}>
          <OnboardingLabel>API Key</OnboardingLabel>
          <pre>{org?.orgToken}</pre>
        </Col>
      </Row>

      <h5>Products</h5>
      <div>
        <Row>
          {hasProductType(ProductType.FormCapture) &&
            <MetricCard color='info' faIcon='list' label='Form Capture' value={0} to={ManagementRoutes.FORMS} />
          }
          {hasProductType(ProductType.UserAuthAndManagement) &&
            <MetricCard color='success' faIcon='user' label='User Management' value={0} to={ManagementRoutes.USERS} />
          }
        </Row>
      </div>

      <Row>
        <Col md={6}>
          <h5>Add New Subscription</h5>
          <ProductSelection
            availableProducts={availableProducts.filter(product => !subscriptions.find(sub => sub.productId === product.id))}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <Button
            disabled={selectedProducts.size === 0}
            style={{ width: '100%' }}
            onClick={onAddSubscription}
          >
            Add New Subscriptions - {formatCurrency(sum(availableProducts.filter(product => selectedProducts.has(product.id)).map(item => item.priceCents)))}/mo + tax
          </Button>
        </Col>

        <Col md={6}>
          <h5>Existing Subscriptions</h5>
          <TableWrapper columns={['Name', 'Price', 'Created At', 'ID', 'Actions']}>
            {subscriptions.map(item => (
              <tr key={item.id}>
                <td>{item.productId}</td>
                <td>{formatCurrency(item.product.priceCents)}</td>
                <td>{formatDate(item.createdAt)}</td>
                <td>{item.stripeSubscriptionItemId}</td>
                <td>
                  <Button size='sm' variant='danger' onClick={onCancel(item)}>Cancel</Button>
                </td>

              </tr>
            ))}
          </TableWrapper>
        </Col>
      </Row>

      {/* <GqlQueryRender query={adminMetricsQuery}>
        {({
          adminMetrics: { userCount, },
        }) => {

          return (
            <div>
              <Row>
                <MetricCard color='success' faIcon='user' label='App Users' value={userCount} to={AdminRoutes.USERS} />
              </Row>
            </div>
          );
        }}
      </GqlQueryRender> */}
    </div >
  );
}
