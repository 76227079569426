import React, { ReactNode } from 'react';
import { Sidebar } from 'components/Sidebar';
import { Navbar } from 'components/Navbar';
import { NavbarLink } from 'components/NavbarLink';
import { ManagementRoutes } from 'shared/Routes';
import { AuthContext } from 'shared/Authentication';
import { compact, first } from 'lodash';
import { ProductType } from 'types/gqlReactTypings.generated.d';

interface IProps {
  children?: ReactNode | ReactNode[]
  match?: any;
}

export const ManagementSidebar: React.FC<IProps> = ({ children, match }: IProps) => {
  const { currentUser } = React.useContext(AuthContext);
  const org = first(currentUser?.organizations);
  const subscriptions = compact(org?.productSubscriptions);

  const hasProductType = (type: ProductType) => {
    return subscriptions.some(item => item.productId === type)
  }

  return (
    <div id='wrapper'>
      <Sidebar>

        <NavbarLink label='Dashboard' active faIcon='tachometer-alt' route={ManagementRoutes.HOME} />

        <hr className="sidebar-divider" />
        {hasProductType(ProductType.UserAuthAndManagement) &&
          <NavbarLink label='User Management' active faIcon='user' route={ManagementRoutes.USERS} />
        }

        {hasProductType(ProductType.FormCapture) &&
          <NavbarLink label='Form Capture' active faIcon='list' route={ManagementRoutes.FORMS} />
        }

      </Sidebar>

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar match={match} />

          <div className="container-fluid">
            {children}
          </div>
        </div>
      </div>
    </div >
  )
}
