import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ManagementRoutes } from 'shared/Routes';
import { ManagementSidebar } from './components/managementSidebar';
import { ManagementDashboard } from './managementDashboard';
import { ManagementForms } from './managementForms';
import { ManagementUsers } from './managementUsers';

interface IProps {
  match?: any;
}

export const ManagementRouter: React.FC<IProps> = ({ match }: IProps) => {
  console.error("management router");
  return (
    <ManagementSidebar match={match}>
      <Routes>
        <Route path={'/'} Component={ManagementDashboard} />
        <Route path={ManagementRoutes.USERS} Component={ManagementUsers} />
        <Route path={ManagementRoutes.FORMS} Component={ManagementForms} />

        <Route path={ManagementRoutes.HOME} Component={ManagementDashboard} />
      </Routes>
    </ManagementSidebar>
  )
}
