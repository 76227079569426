// @ts-ignore
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { gqlClient } from 'shared/gql/GqlClient';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';


console.error('client', gqlClient);
// eslint-disable-next-line react/no-render-return-value
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ApolloProvider client={gqlClient}>
    <BrowserRouter>

      <App />
    </BrowserRouter>

  </ApolloProvider>,
);

// @ts-ignore
if (module.hot) {
  console.log('hot!');
  // @ts-ignore
  module.hot.accept();
  // @ts-ignore
  // module.hot.accept('./App', () => {
  //   console.log('hotter!');
  //   const NextApp = require('./App').default;
  //   render(NextApp);
  // })
}

// Unregister the CRA service worker - we're going to rely on normal browser caching for now.
